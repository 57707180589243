//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import StatisticsSystemStatusCurrencies from '~/views/StatisticsSystemStatus/StatisticsSystemStatusCurrencies/StatisticsSystemStatusCurrencies.vue';
import StatisticsSystemStatusMethods from '~/views/StatisticsSystemStatus/StatisticsSystemStatusMethods/StatisticsSystemStatusMethods.vue';

export default {
  components: {
    StatisticsSystemStatusCurrencies,
    StatisticsSystemStatusMethods,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadStatisticsSystemStatusCurrencies(),
      this.loadStatisticsSystemStatusMethods(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('statisticsSystemStatusCurrencies', {
      loadStatisticsSystemStatusCurrencies: 'loadData',
    }),
    ...mapActions('statisticsSystemStatusMethods', {
      loadStatisticsSystemStatusMethods: 'loadData',
    }),
  },
};
