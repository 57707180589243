export default [
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Transaction method type',
    },
    cell: {
      type: 'slot',
      name: 'transactionMethodType',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Deposit',
      sort: 'depositFunds',
    },
    cell: {
      type: 'text',
      value: (e) => e.depositFunds,
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Deposit count',
      sort: 'depositCount',
    },
    cell: {
      type: 'text',
      value: (e) => e.depositCount,
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Withdrawal',
      sort: 'withdrawalFunds',
    },
    cell: {
      type: 'text',
      value: (e) => e.withdrawalFunds,
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Withdrawal count',
      sort: 'withdrawalCount',
    },
    cell: {
      type: 'text',
      value: (e) => e.withdrawalCount,
    },
  },
];
