//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        currencyIdList: [],
        transactionMethodTypeList: [],
        dateFrom: '',
        dateTo: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('statisticsSystemStatusMethods', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('statisticsSystemStatusMethods', ['setFilters']),
  },
};
