export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.curencyTitle,
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Balance on wallets',
      sort: 'wallets',
    },
    cell: {
      type: 'text',
      value: (e) => e.balanceOnWallets,
    },
  },
  {
    width: 18,
    header: {
      type: 'slot',
      name: 'diffBalanceHeader',
    },
    cell: {
      type: 'slot',
      name: 'diffBalance',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'User balances',
      sort: 'balanceUser',
    },
    cell: {
      type: 'text',
      value: (e) => e.balanceUser,
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Balance on deposit',
      sort: 'fundsDeposits',
    },
    cell: {
      type: 'text',
      value: (e) => e.fundsDeposits,
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Balance on withdrawal',
      sort: 'fundsWithdrawals',
    },
    cell: {
      type: 'text',
      value: (e) => e.fundsWithdrawals,
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Balance in orders',
      sort: 'fundsOrders',
    },
    cell: {
      type: 'text',
      value: (e) => e.fundsOrders,
    },
  },
];
