var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"currencies"},[_c('div',{staticClass:"currencies__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.TOTAL_BALANCES,"command":_vm.requestData}})],1),_c('div',{staticClass:"currencies__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"diffBalanceHeader",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Diff Wallets & Balances'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Difference between hot wallets and user balances'))+" ")])])]},proxy:true},{key:"diffBalance",fn:function(ref){
var row = ref.row;
return [_c('span',{class:[
            {
              'success--text' : row.diffBalance > 0
            },
            {
              'error--text' : row.diffBalance < 0
            }
          ]},[_vm._v(" "+_vm._s(row.diffBalance)+" ")])]}}])})],1),_c('div',{staticClass:"mb-12"})])}
var staticRenderFns = []

export { render, staticRenderFns }